import React from 'react'
import './assets/css/academy.css';
import Navbar from './navBar';
import WhatsAppButton from './whatsapp';
function Pentesting_professional() {
  return (
    <div className='academy'>
    <Navbar/>
    <div className='container mb-1'>
    <div className=' pentesting-professional  mt-5 rounded-4' >
    <div className="d-flex align-items-center justify-content-center  fadeInUp pentesting-professional_bg"  >
             <div className="text-center">
               <h1 className="mb-3 heading">
                 <span className="text-uppercase text-light heading">Pentesting Professional</span>
               </h1>
               <h2 className='sub-heading'><i>Elevate Your Career</i></h2>
             </div>
           </div>
    <div className="row pt-2">
    
    <div className="col ">
      <p className="text-align-left pt-4 para_content ">
      Nalaemton Infotech’s Penetration testing Professional course will certify the successful 
      candidate has the knowledge and skills required to plan and scope a 
      penetration testing engagement including vulnerability scanning, analyze 
      results and produce a written report with remediation techniques.     
      </p>
    </div>
    
   </div>
    
   
    <div className="row mt-3 d-flex align-items-center justify-content-center ">
    <div className='col-lg-6 p-4  '>
    <h3 className='text-danger  para_heading'>Topics Covered</h3>
    <ul >
      <li> Web Application Penetration Testing</li>
      <li>Mobile Application Penetration Testing</li>  
      <li> API Penetration Testing</li>
      <li> Attacks and Exploits</li>
      <li> Tools and Code Analysis
      </li>
    </ul>
    
   </div>
   
   <div className="col-lg-6 p-4  ">
   <h3 className='text-danger para_heading'>Salient Features</h3>
   <ul className='ms-2'>
    <li> Tailored Curriculum</li>
    <li> Personalized Mentorship</li>
    <li> Hands-on Labs</li>
    <br/>
    <br/>
    <br/>
   </ul>
   </div>
   </div> 
   <div className="row pt-2">
    
    <div className="col ">
      <p className="text-align-left pt-4 para_content ">
      For inquiries and collaboration opportunities, contact us at <a href="mailto:business@nalaemton.com">business@nalaemton.com</a>. 
      </p>
    </div>
    
   </div>
   
         
   <div className="row ">
      {/* <div className="col-lg-6">
         <img src={AN} alt="bc" className="img-fluid " />
       </div>
       <div className=" col-lg-6 mt-5 py-5">
        <h2 className='text-danger text-center'>Register Here</h2>
         <h3 >
         Secure your spot by registering for the course. Limited seats are available, so act fast to embark on this transformative journey.
         </h3>
         <br/>
        
   
       </div> */}
      <p className="text-align-left para_content"></p>
     </div>
   
   
   </div>   
    </div> 
    <div className='whatsapp-logo'>
          <WhatsAppButton />
        </div>
    
    </div>
  )
}

export default Pentesting_professional